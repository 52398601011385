import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { HeaderService } from '../header.service';
import { NavigationService } from '../../nav-shared/navigation.service';
import { AuthenticationService } from '../../nav-shared/authentication.service';
import {
  localStorageRemover,
  localStorageSetter,
  removeCookie,
} from '../../nav-shared/helpers';

import { Link, NavLink } from '../../navigation-links.interface';
import { environment } from '../../../environments/environment';

import isEmpty from 'lodash-es/isEmpty';
import * as localForage from 'localforage';

@Component({
  selector: 'nav-my-account-menu',
  templateUrl: './my-account-menu.component.html',
  styleUrls: ['./my-account-menu.component.scss'],
})
export class MyAccountMenuComponent implements OnInit, OnDestroy {
  @Input() themeMode: string = 'default';

  accountNav: NavLink;
  componentFocus: boolean;
  currentUrl: string;
  orderForage: LocalForage;
  showStencils: boolean = false;
  subscriptions: Subscription[] = [];
  userData: any;
  userSignedIn: boolean;

  constructor(
    private authService: AuthenticationService,
    private headerService: HeaderService,
    private navService: NavigationService,
    private store: Store<any>
  ) {}

  ngOnInit() {
    this.orderForage = localForage.createInstance({ name: 'Avery Search' });
    this.currentUrl = window.location.pathname;
    this.subscriptions = [
      this.getProfileDataSubscription(),
      this.isStencilUserSubscription(),
      this.myAccountNavDataSubscription(),
      this.userDataSubscription(),
      this.userSignedInSubscription(),
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  /**
   * get My Account Navigation data
   *
   * @returns {subscription}
   * @memberof MyAccountMenuComponent
   */
  myAccountNavDataSubscription(): Subscription {
    return this.navService.navLinkData
      .pipe(filter((data) => !isEmpty(data)))
      .subscribe((value) => {
        this.accountNav = value.navData.find((link: NavLink) => {
          return link.id === 'nav-accounts';
        });
      });
  }

  /**
   * Get the data for the signed in user
   *
   * @returns {subscription}
   * @memberof MyAccountMenuComponent
   */
  userDataSubscription(): Subscription {
    return this.store.select('profile', 'profileData').subscribe((data) => {
      this.userData = data;
    });
  }

  /**
   * Checks if the user is signed in or not
   * Remove the focus from the component once signed in
   *
   * @returns {subscription}
   * @memberof MyAccountMenuComponent
   */
  userSignedInSubscription(): Subscription {
    return this.authService.userSignedIn.subscribe((signedIn) => {
      this.userSignedIn = signedIn;

      if (this.userSignedIn) {
        this.componentFocus = false;
      }
    });
  }

  /**
   * Get the users profile data
   *
   * @returns {subscription}
   * @memberof MyAccountMenuComponent
   */
  getProfileDataSubscription(): Subscription {
    return this.store
      .select('profile', 'newEmail')
      .subscribe((email: string) => {
        if (!isEmpty(email)) {
          this.authService.getToken(true).subscribe((authData: any) => {
            const l_objAt = JSON.stringify({
              u_at: authData.data.access_token,
              expiry: Date.now() + 60 * 60 * 1000,
            });

            localStorageSetter('u_at', l_objAt);
            localStorageSetter('u_rt', authData.data.refresh_token);
          });
        }
      });
  }

  /**
   * checks if the user has any stencils in their account
   *
   * @return {Subscription}
   * @memberOf MyAccountMenuComponent
   */
  isStencilUserSubscription() {
    return this.authService.stencilExists.subscribe((isStencil: boolean) => {
      this.showStencils = isStencil;
    });
  }

  /**
   * Checks to see if the myaccount dropdown menu should display stencils.
   * @param {Link} link
   * @param {number} idx
   * @return {boolean}
   * @memberOf MyAccountMenuComponent
   */
  checkForStencils(link: Link, idx: number): boolean {
    if (idx === 1 && link.title === 'Stencils') {
      return this.showStencils;
    }
    return true;
  }

  /**
   * Takes a route and generates the full link
   *
   * @param {string} route
   * @returns {string}
   * @memberof MyAccountMenuComponent
   */
  generateLink(route: string): string {
    return this.navService.generateLink(route);
  }

  /**
   * returns the url for the my account page
   *
   * @param {string} page
   * @returns {string}
   * @memberof MyAccountMenuComponent
   */
  myAccountUrl(page: string): string {
    return environment.domain + '/myaccount/' + page;
  }

  /**
   * This will close the myaccount mobile and desktop menu when the user is navigating to any of the my account pages.
   *
   * @param {string} linkTitle
   * @memberOf MyAccountMenuComponent
   */
  closeAcctMenu(linkTitle: string) {
    this.headerService.mobileNavActive.next(false);
  }

  /**
   * Function handles signing out the user and redirecting the users to the sign-in page from the myaccount page.
   * @memberOf MyAccountMenuComponent
   */
  handleSignOff() {
    this.authService.signOutUser().subscribe((data) => {
      if (data['status'] === 'logged_out') {
        this.authService.removeItems();
        this.authService.userSignedInUpdate(false);
        this.authService.stencilExists.next(false);
        localStorageRemover('u_at');
        localStorageRemover('u_i');
        localStorageRemover('u_rt');
        removeCookie('wwj');
        removeCookie('stencilUser', '.avery.com');
        removeCookie('btid');

        if (this.currentUrl.indexOf('myaccount') !== -1) {
          setTimeout(() => {
            if (this.themeMode === 'industrial') {
              window.location.href = `${environment.domain}/industrial`;
            } else {
              window.location.href = `${environment.domain}`;
            }
          }, 200);
        }
      }
    });
  }
}
