import { Component, Input, OnInit } from '@angular/core';
import { SafeHtml, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-promo-alert',
  templateUrl: './promo-alert.component.html',
  styleUrls: ['./promo-alert.component.scss'],
})
export class PromoAlertComponent implements OnInit {
  @Input() promoValue: SafeUrl;
  countdownTime: number = 20000; // 20 seconds
  promoMessage: SafeHtml = null;

  constructor() {}

  ngOnInit() {
    if (this.promoValue) {
      this.promoMessage =
        this.promoValue === 'default'
          ? 'Success! Promo code will be applied at checkout'
          : `Success! Promo code <strong>${this.promoValue}</strong> will be applied at checkout`;
    }
    setTimeout(() => this.closePromo(), this.countdownTime); // closes promo after 20 seconds
  }

  /**
   *  Disables the Promo Alert
   *
   * @memberof PromoAlertComponent
   */
  closePromo() {
    const promoElement = document.querySelector('.promo-container');
    if (promoElement) {
      promoElement.classList.add('slide-out');

      setTimeout(() => {
        this.promoMessage = null; // Hide promo after animation
      }, 300);
    } else {
      this.promoMessage = null;
    }
  }
}
