import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

import has from 'lodash-es/has';
import isEmpty from 'lodash-es/isEmpty';
import * as localForage from 'localforage';
import Base64 from 'crypto-js/enc-base64';
import AES from 'crypto-js/aes';

import { createCookie, getCookie, localStorageGetter } from './helpers';
import { SignInInterface, Stencil } from './models/interfaces';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthenticationService {
  accountUrl = environment.accountService;
  consumerPrivacyUrl = environment.consumerPrivacyUrl;
  downloadStatus = new BehaviorSubject<string>('pending');
  includeGuest = new BehaviorSubject<boolean>(false);
  localForage: LocalForage;
  ssOUrl = environment.ssoService;
  stencilExists = new BehaviorSubject<boolean>(false);
  userSignedIn = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.localForage = localForage.createInstance({ name: 'Avery Search' });
  }

  /**
   * Signing in user service function
   *
   * @param {string} username
   * @param {string} password
   * @returns {Observable<SignInInterface>}
   * @memberof AuthenticationService
   */
  signInUser(username: string, password: string): Observable<SignInInterface> {
    const data = {
      username,
      password,
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
    });
    const options = {
      headers,
      withCredentials: true,
    };
    const encryptSignIn = this.encryptData.bind(this)(data);

    return this.http
      .post(`${this.ssOUrl}/sign-in`, { data: encryptSignIn }, options)
      .pipe(
        map((res: any) => {
          return res.data;
        })
      );
  }

  /**
   * Service that will check to see if the user's email is registered in the SSO.
   *
   * @param {string} [userEmail='']
   * @returns {Observable<any>}
   * @memberof AuthenticationService
   */
  validateUserExists(userEmail: string = ''): Observable<any> {
    const data = { email: this.encryptString(userEmail) };
    return this.http.post(`${this.accountUrl}/checkUser`, data);
  }

  /**
   * Gets the latest user info
   *
   * @param {any} userAccessToken
   * @returns {Observable<any>}
   * @memberof AuthenticationService
   */
  getUserInfo(userAccessToken): Observable<any> {
    return this.http.get(`${this.accountUrl}/user?user_at=${userAccessToken}`);
  }

  /**
   * Calls service to fetch stencil data from dpo.
   * @return {Observable<any>}
   */
  getStencilList(): Observable<any> {
    const options = { withCredentials: true };
    const params = new HttpParams()
      .set('deploymentId', environment.dpoDeploymentID)
      .append('consumer', 'Avery')
      .append('sortField', 'timeCreatedDesc');
    return this.http.get(
      `${environment.dpo3}/stencils?${params.toString()}`,
      options
    );
  }

  /**
   * Validate's user authentication
   * @return {Observable<any>}
   * @memberof AuthenticationService
   */
  validateAuth(): Observable<any> {
    const signedIn = this.userSignedIn.getValue();

    if (!signedIn) {
      return this.http
        .get(`${environment.ssoService}/validate-user`, {
          withCredentials: true,
        })
        .pipe(
          map((data: any) => {
            if (has(data, 'consumerId') && has(data, environment.authKey)) {
              return true;
            } else {
              return false;
            }
          })
        );
    } else {
      return of(true);
    }
  }

  /**
   * Service function that will handle signing out the user.
   *
   * @returns {Observable<any>}
   * @memberof AuthenticationService
   */
  signOutUser(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      headers,
      withCredentials: true,
    };
    const l_objAT = localStorageGetter('u_at');
    const obj = { access_token: l_objAT['u_at'] };

    return this.http.post(`${this.ssOUrl}/logout`, obj, options);
  }

  /**
   * Service function that will handle creating a new user.
   *
   * @param {any} createData
   * @returns {Observable<any>}
   * @memberof AuthenticationService
   */
  createAccount(createData: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const encryptAccountData = this.encryptData.bind(this)(createData);

    return this.http.post(
      `${this.accountUrl}/create`,
      { data: encryptAccountData },
      { headers }
    );
  }

  /**
   * Service function that handles subscribing the user to the newsletter.
   *
   * @param {any} emailData
   * @returns {Observable<any>}
   * @memberof AuthenticationService
   */
  emailSignUp(emailData: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const encryptedEmailData = this.encryptData.bind(this)(emailData);
    return this.http.post(
      `${this.accountUrl}/email-signup`,
      { data: encryptedEmailData },
      { headers }
    );
  }

  /**
   * Service function that will handle refreshing the user's tokens in order for authentication to persist
   *
   * @returns {Observable<any>}
   * @memberof AuthenticationService
   */
  refreshToken(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const l_strRT = localStorageGetter('u_rt');
    let l_strRefreshToken = '';

    if (!isEmpty(l_strRT)) {
      l_strRefreshToken = `refresh_token=${l_strRT}&redirect_uri=${environment.appDomain}/myavery/oauth2/redirect`;
    }

    return this.http
      .post(`${this.ssOUrl}/refresh_token`, l_strRefreshToken, { headers })
      .pipe(retry(3));
  }

  /**
   * Function used to call angular backend to get a new refresh token - when it is missing on the
   * client side.
   *
   * @param {boolean} [cookieRefresh=false]
   * @returns {Observable<any>}
   * @memberof AuthenticationService
   */
  getToken(cookieRefresh = false): Observable<any> {
    const options = {
      withCredentials: true,
    };
    const requestBody: any = {
      redirect_uri: `${environment.appDomain}/myavery/oauth2/redirect`,
    };

    // if this condition is true, then an argument to refresh the authentication cookies is passed
    // to the backend endpoint.
    if (cookieRefresh) {
      // l_strRefreshToken += '&cookie_refresh=true';
      requestBody.cookie_refresh = true;
    }

    return this.http.post(`${this.ssOUrl}/get_token`, requestBody, options);
  }

  /**
   * Asynchronous function that removes the user's cached data
   * @return {Promise<void>}
   * @memberOf AuthenticationService
   */
  removeItems() {
    // Remove previous order results
    try {
      this.localForage.removeItem('stencilList');
      this.localForage.removeItem('projectList');
      this.localForage.removeItem('fileList');
      this.localForage.removeItem('cordialStatus');
    } catch (e) {
      console.error('Unable to clear orders');
    }
  }

  /**
   * Service that makes a request to send a link that will allow the user to reset their password.
   *
   * @param {string} email
   * @returns {Observable<any>}
   * @memberof AuthenticationService
   */
  resetPwdRequest(email: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = {
      headers,
      withCredentials: true,
    };
    const l_objBody = { email };

    return this.http.post(
      `${this.accountUrl}/reset-password-request`,
      l_objBody,
      options
    );
  }

  /**
   * Function allows the user to change their password after their resetId has been validated.
   *
   * @param {any} p_objBody
   * @returns {Observable<any>}
   * @memberof AuthenticationService
   */
  changePassword(p_objBody): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = {
      headers,
      withCredentials: true,
    };

    return this.http.post(
      `${this.accountUrl}/change-password`,
      p_objBody,
      options
    );
  }

  /**
   * Retrieves data from the demandbase API
   * TODO: think about moving this function to a different service.
   * @return {Observable<Object>}
   */
  getDemandBaseInfo(): Observable<any> {
    return this.http.get(environment.demandBase, { responseType: 'json' });
  }

  /**
   * Updates the cached data for stencils
   * @param {Stencil[]} stencilList
   */
  updateStencilStorage(stencilList: Stencil[]) {
    if (stencilList.length > 0 && isEmpty(getCookie('stencilUser'))) {
      createCookie('stencilUser', true, 14, '.avery.com');
    }
    this.localForage.setItem('stencilList', stencilList);
  }

  /**
   * Sets the Google Analytics user ID
   *
   * @memberof AuthenticationService
   */
  setGAUser(userId: string) {
    if (!isEmpty(userId)) {
      try {
        ga('set', 'userId', userId);
      } catch (e) {
        console.error(e);
      }
    }
  }

  /**
   * Emit a DY login event when a user logs in
   * @param {string} userId
   *  @memberof AuthenticationService
   */
  DYEventLogin(userId: string) {
    if (!isEmpty(userId)) {
      try {
        DY.API('event', {
          name: 'login',
          properties: {
            dyType: 'login-v1',
            cuid: userId,
            cuidType: 'consumer_id',
          },
        });
      } catch (O_o) {
        console.error(O_o);
      }
    }
  }

  /**
   * Emit a DY Identify User event when stay logged in
   * @param {string} userId
   * @memberof AuthenticationService
   */
  DYEventIdentifyUser(userId: string) {
    if (!isEmpty(userId)) {
      try {
        DY.API('event', {
          name: 'Identify',
          properties: {
            dyType: 'identify-v1',
            cuid: userId,
            cuidType: 'consumerId',
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  /**
   * Encrypts a string. Good for sensitve info
   *
   * @param {string} str
   * @returns {string}
   * @memberof AuthenticationService
   */
  encryptString(str: string): string {
    const key = Base64.parse(environment.authKey.substring(0, 16));
    const iv = Base64.parse(environment.authKey.substring(16));
    return AES.encrypt(str, key, { iv }).toString().replace(/\//g, 'Avy21Ld');
  }

  /**
   * Encrypts data ¯\_(ツ)_/¯
   *
   * @private
   * @param {*} dataToEncrypt
   * @returns {string}
   * @memberof AuthenticationService
   */
  private encryptData(dataToEncrypt: any): string {
    const key = Base64.parse(environment.authKey.substring(0, 16));
    const iv = Base64.parse(environment.authKey.substring(16));
    const encryptedResults = AES.encrypt(JSON.stringify(dataToEncrypt), key, {
      iv,
    });
    return encryptedResults.toString().replace(/\//g, 'Avy21Ld');
  }

  /**
   * Service function that handles consumer or subscriber privacy information.
   *
   * @param {any} emailData
   * @returns {Observable<any>}
   * @memberof AuthenticationService
   */
  privacyEmailValidate(emailData: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const encryptedEmailData = this.encryptData(emailData);
    return this.http.post(
      `${this.consumerPrivacyUrl}/get-consumer-subscriber`,
      { data: encryptedEmailData },
      { headers }
    );
  }

  /**
   * Service function that handles newsletter emails opt-out.
   *
   * @param {any} emailData
   * @returns {Observable<any>}
   * @memberof AuthenticationService
   */
  emailOptOutRequest(emailData: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const encryptedEmailData = this.encryptData(emailData);
    return this.http.post(
      `${this.consumerPrivacyUrl}/opt-out`,
      { data: encryptedEmailData },
      { headers }
    );
  }

  /**
   * Service function that verifies email link uuid.
   *
   * @param {any} data
   * @returns {Observable<any>}
   * @memberof AuthenticationService
   */
  verifyEmailLink(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const encryptedEmailData = this.encryptData(data);
    return this.http.post(
      `${this.consumerPrivacyUrl}/verify-email-link`,
      { data: encryptedEmailData },
      { headers }
    );
  }

  /**
   * Service function that handles request to download data.
   *
   * @param {any} data
   * @returns {Observable<any>}
   * @memberof AuthenticationService
   */
  downloadData(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const encryptedEmailData = this.encryptData(data);
    return this.http.post(
      `${this.consumerPrivacyUrl}/get-consumer-download-data`,
      { data: encryptedEmailData },
      { headers }
    );
  }

  /**
   * Service function that handles request to download show response data.
   *
   * @param {any} data
   * @returns {Observable<any>}
   * @memberof AuthenticationService
   */
  downloadShowResponseData(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const encryptedEmailData = this.encryptData(data);
    return this.http.post(
      `${this.consumerPrivacyUrl}/download-show-response`,
      { data: encryptedEmailData },
      { headers }
    );
  }

  /**
   * Service function that handles request to delete data.
   *
   * @param {any} data
   * @returns {Observable<any>}
   * @memberof AuthenticationService
   */
  deleteData(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const encryptedEmailData = this.encryptData(data);
    return this.http.post(
      `${this.consumerPrivacyUrl}/do-consumer-delete-data`,
      { data: encryptedEmailData },
      { headers }
    );
  }

  /**
   * Update the BS for user's signed into MyAccount.
   * @param signedIn
   * @memberOf AuthenticationService
   */
  userSignedInUpdate(signedIn: boolean): void {
    this.userSignedIn.next(signedIn);
  }
}
